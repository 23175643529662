import React from "react";
import logo from "../images/KalbReportLogo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "gatsby";

const Header = () => (
  <section className="header navigation">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Navbar collapseOnSelect expand="md">
            <Navbar.Brand href="/" className="col-lg-4 col-md-3 col-sm-8">
              <img src={logo} alt="logo" width={350} className="img-fluid" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Link className="nav-link" to="/" activeClassName="active">
                  Home
                </Link>
                <Link
                  className="nav-link"
                  to="/about-the-series"
                  activeClassName="active"
                >
                  About the Series
                </Link>
                <Link
                  className="nav-link"
                  to="/meet-the-host"
                  activeClassName="active"
                >
                  Meet the Host
                </Link>
                <Link
                  className="nav-link"
                  to="/archive"
                  activeClassName="active"
                >
                  Archive
                </Link>
                <Link
                  className="nav-link"
                  to="/contact"
                  activeClassName="active"
                >
                  Contact Us
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  </section>
);

export default Header;
