import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Program from "../components/program";

const ArchivePage = ({ data }) => {
  const programSeasons = {};
  data.programs.edges.forEach(program => {
    const programDate = new Date(Date.parse(program.node.frontmatter.date));
    const month = programDate.getMonth();
    const year =
      month > 6 ? programDate.getFullYear() : programDate.getFullYear() - 1;
    if (programSeasons[year]) {
      programSeasons[year].push(program);
    } else {
      programSeasons[year] = [program];
    }
  });
  return (
    <Layout>
      <SEO
        title="The Kalb Report | Archive"
        keywords={[`kalb`, `kalb report`, `the kalb report`]}
      />
      <section className="small-page-heading">
        <div className="container">
          <h1>Archive</h1>
        </div>
      </section>
      <section
        className="about-2 section bg-gray about-the-program-section"
        id="about"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {Object.keys(programSeasons)
                .sort((a, b) => a - b)
                .reverse()
                .map(season => (
                  <div>
                    <div className="season-divider">
                      <h3>
                        {season}-{parseInt(season) + 1} Season
                      </h3>
                      <div className="divider" />
                    </div>
                    {programSeasons[season].map(program => (
                      <Program
                        data={program.node.frontmatter}
                        key={program.node.frontmatter.date}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    programs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(programs)/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            photo {
              childImageSharp {
                fixed(width: 250) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            description
            guests {
              name
              guest_title
            }
            videos {
              link_title
              url
            }
          }
        }
      }
    }
  }
`;

export default ArchivePage;
