import React from "react";
import Img from "gatsby-image";

const Program = ({ data }) => (
  <div className="archive-program">
    <a name={data.slug} style={{ display: "none" }} />
    <div className="program-header">
      <h3 class="program-date">
        {new Date(Date.parse(data.date)).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "America/Los_Angeles",
        })}
      </h3>
      <h3 className="program-title">
        <a href={`#${data.slug}`}>{data.title}</a>
      </h3>
    </div>
    <div className="row">
      <div className="offset-md-3 col-md-6" />
    </div>
    <div className="row">
      <div className="program-photo col-md-3">
        {data.photo ? (
          <Img fixed={data.photo.childImageSharp.fixed} fadeIn={false} />
        ) : null}
      </div>
      <div className="program-details col-md-6">
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </div>
      <div className="col-md-3">
        <div className="guests">
          <h5>Guests:</h5>

          {data.guests.map(guest => (
            <div>
              <span className="guest-name">{guest.name}</span>
              {guest.guest_title ? (
                <span>
                  {", "}
                  <span
                    dangerouslySetInnerHTML={{ __html: guest.guest_title }}
                  />
                </span>
              ) : null}
            </div>
          ))}
        </div>
        <hr />
        <div className="watch-program">
          <h5>Watch Program:</h5>
          {data.videos
            .map(({ link_title, url }) => (
              <a key={url} href={url} target="_blank" rel="noopener noreferrer">
                {link_title}
              </a>
            ))
            .reduce((acc, c) => {
              return [...acc, <span>&nbsp;|&nbsp;</span>, c];
            })}
        </div>
      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  </div>
);

export default Program;
